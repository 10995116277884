import utils from './components/utils/utils';
import cart from './checkout/cart';
import collapsibleItem from './components/collapsibleItem';
import cookie from './components/cookie';
import countrySelector from './components/countrySelector';
import footer from './components/footer';
import login from './login/login';
import menu from './components/menu';
import miniCart from './components/miniCart';
import plugins from './components/plugins';
import spinner from './components/spinner';
import search from './components/search';
import sizeChart from './components/sizeChart'; // sizeChart module is available globally due to quickView modals
import storeLocator from './storeLocator/storeLocator';
import addToWishlist from './components/addToWishlist';
import analyticsFormData from './seo/gtm/formSubmit';
import shippingBanner from './components/shippingBanner';
import changeLocale from './components/changeLocale';
import passwordResetModal from "./login/passwordResetModal";

/**
 * Initialize global js assets
 */

const mainInit = () => {
    utils.init;
    spinner.init;
    plugins.init;
    menu.init;
    cart.init;
    //consentTracking.init;
    collapsibleItem.init;
    cookie.init;
    countrySelector.init;
    footer.init;
    login.init; // Login methods have to be included globally since they're globally available through the login layer form in the header menu
    miniCart.init;
    search.init;
    sizeChart.init;
    storeLocator.init;
    addToWishlist.init;
    analyticsFormData.init;
    shippingBanner.init();
    changeLocale.init();
    passwordResetModal();
};

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        mainInit();
    }, false);

} else {
    mainInit();
}

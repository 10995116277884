'use strict';

var alertNotification = require('../components/alertNotification');
var GtmEvents = require('../seo/gtm/gtmEvents.js');
import scrollAnimate from '../components/scrollAnimate';

/**
 * appends params to a URL
 * @param {string} msg - data returned from the server's ajax call
 * @param {string} status - message type
 */
function displayMessage (msg, status= 'alert-danger') {
    $.spinner().stop();

    alertNotification.set({
        alertName: 'email-signup',
        alertOptions: {
            message: msg,
            status: status
        }
    });
}
class Footer
{
    subscribeEmail () {
        $('body').on('submit', '.newsletter-subscribe-form' ,function (e) {
            e.preventDefault();
            var form = $(e.currentTarget);
            var url = form.attr('action');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.error) {
                        $.spinner().stop();
                        $('body').trigger('newsletter:subscribeSuccess');
                        GtmEvents.sendNewsletterSuccess();
                        displayMessage(data.msg, 'alert-success');
                    } else {
                        displayMessage(data.msg);
                    }
                },
                error: function (err) {
                    displayMessage(err.msg);
                }
            });
        });
    }

    backToTop () {
        $('.back-to-top-btn').on('click', (e) => {
            e.preventDefault();
            scrollAnimate();
        });
    }

    get init () {
        return {
            subscribeEmail: this.subscribeEmail(),
            backToTop: this.backToTop()
        };
    }

}

const footer = new Footer;
export default footer;


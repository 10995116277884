
/**
     * Clear timeout.
     * @param {Number} timeoutID - Timer created by setTimeout.
     */

const resetTimeout = timeoutID => {
    if (typeof timeoutID === 'number') {
        window.clearTimeout(timeoutID);
    }
};

export {
    resetTimeout
};

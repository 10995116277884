'use strict';

module.exports = function (element, timing = 500) {
    // "-200" is here set to fix jQuery offset miscalculation
    let position = element && element.length ? element.offset().top - 200 : 0;

    $('html, body').animate({
        scrollTop: position
    }, timing);
    if (!element) {
        $('.logo-home').focus();
    }
};

'use strict';

var alertNotification = require('./alertNotification');
var GtmEvents = require('../seo/gtm/gtmEvents');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage (data) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    alertNotification.set(
        {
            alertName: 'add-to-wishlist',
            alertOptions: {
                message: data.msg,
                status: status    
            }
        }
    );
}

/**
 * When add one element to wishlist send product name to datalayer
 * @param {String} productName - Name of product succesfully added to Wishlist
 */

function sendWishlistData (productName){
    GtmEvents.addToWishlist(productName);
}

class AddToWishlist {

    addProductToWishlist () {
        $('body').on('click', '.add-to-wish-list' ,function (e) {
            e.preventDefault();
            var url = $(this).data('href');
            var button = $(this);
            var pid = $(this).closest('.product-wishlist').attr('data-pid');
            var productName = $(this).closest('.product-wishlist').attr('data-productname');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    if (!data.error) {
                        button.children('svg').css({ fill: '#EAA5B5' }); // Color wishlist svg if product has been succesfully added to Wishlist
                        sendWishlistData(productName);
                    }
                    displayMessage(data);
                },
                error: function (err) {
                    displayMessage(err);
                }
            });
        });
    }

    get init () {
        return {
            addProductToWishlist: this.addProductToWishlist()
        };
    }
}

const addToWishlist = new AddToWishlist;
export default addToWishlist;

'use strict';
class ShippingBanner{
    constructor (){
        this.$changeLocale = $('#changeLocale');
    }
    
    init (){
        return {
            openChangeLocal: this.openChangeLocal(),
            closeChangeLocal: this.closeChangeLocal()
        };
    }

    openChangeLocal (){
        $('.header-button-locale').on('click', () => {
            this.$changeLocale.modal('show');
        });

        const languagesList = $('#change-locale-language').children().length;
        if(languagesList <= 1){
            $('#change-locale-language').prop('disabled', true);
        }
    }

    closeChangeLocal (){
        $('.modal-header-btn-close').on('click', () => {
            this.$changeLocale.modal('hide');
        });
    }

}

const shippingBanner = new ShippingBanner;
export default shippingBanner;
'use strict';

import focusOut from './accessibility/focusOut';
import {resetTimeout} from '../components/resetTimeout';

var updateMiniCart = true;
class MiniCart {

    minicart () {
        $('.minicart').on('count:update', function (event, count) {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity, .minicart-mobile .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            }
        });

        $('.minicart').on('mouseenter focusin touchstart minicart:open', function (event) {
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            resetTimeout(window.minicartTimer); // Refresh setTimeout. Avoid minicart closing on mousenter, focusin and touchstart if minicart:open has been triggered

            // The minicart:open event is actually triggered after succesfully adding a product to cart
            if(event.type === 'minicart:open') {
                window.minicartTimer = setTimeout(() => {
                    $('.minicart .popover, .minicart .overlay-mark').removeClass('show'); // Close minicart if it's not interacted with for the specified time
                }, 5000);
            }

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover, .minicart .overlay-mark').addClass('show');
                    return;
                }

                $('.minicart .popover, .minicart .overlay-mark').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    $.spinner().stop();
                });
            }
        });

        $('body').on('touchstart click', function (e) {
            if ($('.minicart').has(e.target).length <= 0) {
                $('.minicart .popover, .minicart .overlay-mark').removeClass('show');
            }
        });

        $('.minicart').on('mouseleave focusout', function (event) {
            if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                event.stopPropagation();
                return;
            }
            $('.minicart .popover, .minicart .overlay-mark').removeClass('show');
        });

        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });

        $('body').on('product:afterAddToCart', function () {
            updateMiniCart = true;
        });

        $('body').on('cart:update', function () {
            updateMiniCart = true;
        });
    }

    minicartAccessibility () {
        focusOut('.minicart', () => {
            $('.minicart .popover, .minicart .overlay-mark').removeClass('show');
        });
    }

    get init () {
        return {
            minicart: this.minicart(),
            minicartAccessibility: this.minicartAccessibility()
        };
    }
}

const miniCart = new MiniCart;
export default miniCart;

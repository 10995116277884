import intlTelInput from 'intl-tel-input';

function internationalPhoneNumber () {
    const phoneNumberInput = document.querySelectorAll('.js-international-phone-number');
    phoneNumberInput.forEach(el => {
        intlTelInput(el, {
            formatOnDisplay: false,
            autoPlaceholder: 'off',
            initialCountry: window.dwResources.locale.country,
            preferredCountries: [window.dwResources.locale.country],
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js'
        });
    });

    $('body')
        .on('address:submit profile:edit login:register', e => {
            const form = $(e.target).closest('form');
            const input = form[0].querySelector('.js-international-phone-number');

            if (input) {
                const iti = window.intlTelInputGlobals.getInstance(input);
                $(input).val(iti.getNumber());
            }
        })
        .on('checkout:serializeShipping checkout:serializeBilling', (e, params) => {
            const input = $('.js-international-phone-number', params.form);
            const validityInput = $('[name$=_phoneValidity]', params.form);

            if (input.length) {
                const iti = window.intlTelInputGlobals.getInstance(input[0]);

                validityInput.val(iti.isValidNumber());
                input.val(iti.getNumber());
                params.callback(params.form.serialize());
            }
        })
        .on('shipping:updateShippingAddressFormValues', (e, params) => {
            const input = $('.js-international-phone-number', '.shipping-form');
            const iti = window.intlTelInputGlobals.getInstance(input[0]);

            if (
                params.shipping &&
                params.shipping.shippingAddress &&
                params.shipping.shippingAddress.phone
            ) {
                iti.setNumber(params.shipping.shippingAddress.phone);
            }
        })
        .on('billing:updateBillingAddressFormValues billing:selectBillingAddress', (e, params) => {
            const input = $('.js-international-phone-number', '.shipment-form-billing');
            const iti = window.intlTelInputGlobals.getInstance(input[0]);

            if (params.billing.billingAddress && params.billing.billingAddress.address.phone) {
                iti.setNumber(params.billing.billingAddress.address.phone);
            } else if (params.billing.phone) {
                iti.setNumber(params.billing.phone);
            }
        });
}

export default internationalPhoneNumber;

'use strict';

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

var galleryThumbs = new Swiper(".gallery-thumbs", {
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    slidesPerView: 15,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    direction: "vertical",
});

var galleryMain = new Swiper(".gallery-main", {
    mousewheel: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    thumbs: {
        swiper: galleryThumbs,
    },
    breakpoints: {
        // when window width is >= 992px
        992: {
            direction: "vertical",
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            }
        },
    },
});

var galleryThumbsModal = new Swiper(".gallery-thumbs-modal", {
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    slidesPerView: 8,
    spaceBetween: 35,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: "vertical",
});

var galleryMainModal = new Swiper(".gallery-main-modal", {
    observer: true,
    observeParents: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    // },
    thumbs: {
        swiper: galleryThumbsModal,
    },
    breakpoints: {
        // when window width is >= 992px
        992: {
            direction: "vertical",
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preventInteractionOnTransition: true,
            observer: true,
            observeParents: true,

        },
    },
  });

galleryMain.on('click', function () {
    let index = this.activeIndex;
    galleryMainModal.slideToLoop(index, 0);
    $('.pdp-modal').removeClass('visibility-hidden');
    $('body').addClass('overflow-hidden');
});

$('.pdp-modal .close').on('click', (e) => {
    $('body').removeClass('overflow-hidden');
    $('.pdp-modal').addClass('visibility-hidden');
})

galleryMain.on("slideChangeTransitionStart", function () {
    galleryThumbs.slideTo(galleryMain.activeIndex);
});

// galleryThumbs.on("click", function () {
//     console.log(`log thumbs`);
//     galleryMain.slideTo(galleryThumbs.activeIndex);
// });

/**
 * Get configuration object for the specified slick type. If no match between the current carousel type and one of the available configurations is found, the default Slick configuration is used.
 * Inline example of carousel initialization: <div class="slick-carousel" data-carousel="home"></div>
 * @param {String} carouselType - Current carousel type passed via the 'data-carousel' data attribute
 * @returns {Object} - Slick configuration object
 */

export function getSlickOptions (carouselType) {

    const availableSlickConfigurations = {
        // List of available configurations

        //  home: {
        //    slidesToShow: 1,
        //    slidesToScroll: 1
        // }

        menuCarousel:{
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows : true,
            nextArrow: '<button type="button" class="next-arrow slick-next bg-transparent"></button>',
            prevArrow: '<button type="button" class="prev-arrow slick-prev bg-transparent"></button>',
        },

        oneSlideFullScreenCarousel: {
            adaptiveHeight: true,
            appendDots: $('.box-stories-slick-dots.wg1'),
            appendArrows: $('.slick-media-container.wg1'), // On tablet screens, arrows are not perfectly centered with the media content, since the default container 'stretches' its height due to slick slide's title and subtitle changing their layout position. Appending arrows to the defined container fix the issue
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            nextArrow: '<button type="button" class="arrow-next slick-next bg-transparent rounded-circle border-white d-flex p-0"><svg version="1.1" class="m-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#FFFFFF"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-prev slick-prev bg-transparent rounded-circle border-white d-flex p-0"><svg version="1.1" class="m-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#FFFFFF"/></svg></button>',
            responsive:  [
                {
                    breakpoint: Breakpoints.get('xxl').max,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.wg1'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },

                {
                    breakpoint: Breakpoints.get('xxl').min,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.wg1'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },

                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.wg1'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                }
            ]
        },

        storiesCarousel: {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows : false,
            appendArrows: $('.wg5-desktop-pagination-container'),
            appendDots: $('.wg5-desktop-pagination-container'),
            nextArrow: '<button type="button" class="arrow-right bg-transparent border-1 border-dark stroke-black p-0 d-flex"><svg class="m-auto" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="4" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left bg-transparent border-1 border-dark stroke-black p-0 d-flex"><svg class="m-auto" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="4" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            responsive: [
                {
                    breakpoint: Breakpoints.get('lg').max,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        appendArrows: $('.wg5-desktop-pagination-container'),
                        appendDots: $('.wg5-desktop-pagination-container'),
                    }
                },
                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        appendArrows: $('.wg5-mobile-pagination-container'),
                        appendDots: $('.wg5-mobile-pagination-container'),
                    }
                },
                {
                    breakpoint: Breakpoints.get('sm').min,
                    settings: {
                        appendArrows: $('.wg5-mobile-pagination-container'),
                        appendDots: $('.wg5-mobile-pagination-container'),
                        autoplay: true,
                        autoplaySpeed: 5000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        infinite: true,
                        touchThreshold: 60,
                        arrows: false
                    }
                }
            ]
        },

        OneSlideCarousel: {
            appendDots: $('.box-stories-slick-dots.stories-detail-carousel'),
            //appendArrows: $('.slick-media-container.stories-detail-carousel'),  On tablet screens, arrows are not perfectly centered with the media content, since the default container 'stretches' its height due to slick slide's title and subtitle changing their layout position. Appending arrows to the defined container fix the issue
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            nextArrow: '<button type="button" class="arrow-right bg-transparent border-1 border-dark stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="4" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left bg-transparent border-1 border-dark stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="4" height="8" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            responsive:  [
                {
                    breakpoint: Breakpoints.get('xxl').max,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.stories-detail-carousel'),
                        appendArrows: $('.slick-media-container.stories-detail-carousel'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },

                {
                    breakpoint: Breakpoints.get('xxl').min,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.stories-detail-carousel'),
                        appendArrows: $('.slick-media-container.stories-detail-carousel'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },

                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        appendDots: $('.box-stories-slick-dots.stories-detail-carousel'),
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        },

        relatedProductEinstein: {
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows : true,
            nextArrow: '<button type="button" class="arrow-right stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            responsive: [
                {
                    breakpoint: Breakpoints.get('lg').max,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: Breakpoints.get('sm').max,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 5000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        touchThreshold: 60
                    }
                }
            ]
        },

        storiesCarouselSmUp: {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows : false,
            responsive: [
                {
                    breakpoint: Breakpoints.get('lg').max,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: Breakpoints.get('sm').min,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },

        relatedProduct: {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows : true,
            nextArrow: '<button type="button" class="arrow-right stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            responsive: [
                {
                    breakpoint: Breakpoints.get('lg').max,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: Breakpoints.get('lg').min,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: Breakpoints.get('sm').max,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 5000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        touchThreshold: 60,
                        centerMode: false,
                    }
                }
            ]
        },

        pdpDesktopVerticalCarousel: {
            nextArrow: '<button type="button" class="arrow-right bg-transparent border-0 p-0 rotate-90-clockwise"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="25" height="25" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 "/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left bg-transparent border-0 p-0 mb-3 rotate-90-clockwise"> <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="25" height="25" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 "/></svg></button>',
            infinite: true,
            vertical: true,
            swipeToSlide: true
        },

        pdpMainPic:{
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            touchThreshold: 10,
            asNavFor: '.image-modal-nav',
            nextArrow: '<button type="button" class="arrow-right bg-transparent border-1 border-primary-darker p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left bg-transparent border-1 border-primary-darker p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            responsive: [
                {
                    breakpoint: Breakpoints.get('sm').min,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        },

        pdpMainPicMobile:{
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            fade: true,
            asNavFor: '.image-modal-nav',
            responsive: [
                {
                    breakpoint: Breakpoints.get('sm').min,
                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        nextArrow: '<button type="button" class="arrow-right-mobile"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
                        prevArrow: '<button type="button" class="arrow-left-mobile"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
                        infinite: true,
                        draggable: false,
                        swipe: false
                    }
                }
            ]
        },

        pdpNavGallery:{
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.image-modal-forefront',
            focusOnSelect: true,
            nextArrow: '<button type="button" class="arrow-right bg-transparent border-1 border-primary-darker p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left bg-transparent border-1 border-primary-darker p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="20" height="20" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
            vertical: true
        },

        PDPThumbPics: {
            fade: true,
            slidesToShow: 3,
            slidesToScroll: 2,
            vertical: true
        },

        homepage2Categories: {
            mobileFirst: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            responsive: [{
                breakpoint: Breakpoints.get('sm').max,
                settings: 'unslick'
            }]
        },

        homepageProductCarousel2: {
            mobileFirst: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            nextArrow: '<button type="button" class="arrow-right stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="1.8,27.5 0.2,26.2 10.8,13.9 0.2,1.7 1.8,0.3 13.4,13.9 " style="fill:#F6A1B580"/></svg></button>',
            prevArrow: '<button type="button" class="arrow-left stroke-black p-0"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 14 28" width="5" height="10" style="enable-background:new 0 0 14 28;" xml:space="preserve"><polygon points="12.4,27.5 0.7,13.9 12.4,0.3 13.9,1.7 3.3,13.9 13.9,26.2 " style="fill:#F6A1B580"/></svg></button>',
        },

        homepageMobileCategories: {
            mobileFirst: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            dots: true
        }
    };

    return availableSlickConfigurations.hasOwnProperty(carouselType) ? availableSlickConfigurations[carouselType] : {};
}

/**
 * Split birthday date during registration to check new age's costumer is over 18 years old
 */
function ageParsleyVerification () {
    /* add validation for minimum age */
    window.Parsley.addValidator('minimumage', {
        validateString: function (value, requirements) {
            // get validation requirments
            var reqs = value.split('-'),
                year = reqs[0],
                month = reqs[1],
                day = reqs[2];

            // check if date is a valid
            var birthday = new Date(year + '-' + month + '-' + day);

            // Calculate birtday and check if age is greater than 18
            var today = new Date();

            var age = today.getFullYear() - birthday.getFullYear();
            var m = today.getMonth() - birthday.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
                age--;
            }

            return age >= requirements;
        }
    });
}

$.fn.imgError = function () {
    $(this).parent().find('source').remove();
    this.onerror = '';
    $(this).attr('src', $(this).data('errorimage'));
    return true;
};


class Plugins {
    //
    // bootstrap-tabcollapse is a bootstrap plugin that switches bootstrap tabs component to collapse component for small screens.
    //
    bootstrapTabCollapse () {
        // Initialize tabcollapse(s)
        $('.tab-collapse').each((index, tabCollapse) => {
            $(tabCollapse).tabCollapse(
                {
                    tabsClass: 'd-none d-sm-flex',
                    accordionClass: 'd-block d-sm-none'
                }
            );

            $(tabCollapse).on('shown-accordion.bs.tabcollapse', () => {
                $(tabCollapse).next('.panel-group').find('.panel-collapse').first().addClass('show'); // Show first collapse content
                $(tabCollapse).next('.panel-group').find('.js-tabcollapse-panel-heading').first().removeClass('collapsed'); // Remove the 'collapsed' class from first collapse content. This line follows the above one
                $(tabCollapse).next('.panel-group').find('.js-tabcollapse-panel-heading').first().attr('aria-expanded', 'true'); // Set the aria-expanded state to true. This line follows the two above.
            });
        });
    }

    // Third-party plugin to handle Bootstrap breakpoints via JS (Bootstrap actually does not provide such methods)
    breakpointsjs () {

        // Breakpoint configuration object
        Breakpoints(
            {
                xs: {
                    min: 0,
                    max: 543
                },
                smUp: {
                    min: 544,
                    max: Infinity
                },
                sm: {
                    min: 544,
                    max: 768
                },
                mdUp: {
                    min: 769,
                    max: Infinity,
                },
                md: {
                    min: 769,
                    max: 991,
                },
                lgDown: {
                    min: 0,
                    max: 991
                },
                lgUp: {
                    min: 992,
                    max: Infinity
                },
                lg: {
                    min: 992,
                    max: 1199
                },
                xlUp: {
                    min: 1200,
                    max: Infinity
                },
                xl: {
                    min: 1200,
                    max: 1499
                },
                xxl: {
                    min: 1500,
                    max: Infinity
                }
            }
        );
    }

    slick () {

        // Initialize Slick carousel(s) with provided options, if any
        $('.slick-carousel').each((index, carousel) => {
            // Get the data-carousel-auto-init data attribute. If the data attribute is explicitly set to "false", carousel does not initialize automatically - e.g., data-carousel-auto-init="false"
            let slickAutoInitParameter = $(carousel).attr('data-carousel-auto-init');

            if (!slickAutoInitParameter || slickAutoInitParameter == 'true') {
                $(carousel).slick(getSlickOptions($(carousel).data('carousel')));
            }
        });

        $('.related-observer').each((index, relatedObserver) => {
            let config = {childList: true, subtree: true};

            // sonar issue. observer has already been declared in the upper scope
            // let initEinsteinCarousel = function(mutationsList, observer) {
            // Funzione di initEinsteinCarousel da eseguire quando avvengono le mutazioni
            let initEinsteinCarousel = function(mutationsList, observerParam) {
                let $einsteinCarousel = $(relatedObserver).find('.related-carousel');
                for(var mutation of mutationsList) {
                    if (mutation.type == 'childList') {
                        $einsteinCarousel.slick(getSlickOptions($einsteinCarousel.data('carousel')));
                        observer.disconnect();
                    }
                }
            };

            // Creazione di un'istanza di monitoraggio collegata alla funzione di initEinsteinCarousel
            var observer = new MutationObserver(initEinsteinCarousel);

            // Inizio del monitoraggio del nodo target riguardo le mutazioni configurate

            if ($(relatedObserver).length) {
                observer.observe(relatedObserver, config);
            }
        });

        // Fix Slick bug. As of version 1.8.1, slick arrows do not work if appended inside '.slick-slide' (#issue 3657 - https://github.com/kenwheeler/slick/issues/3657). The following is a workaround to make the previous arrow work properly.
        $('body').on('click', '.slick-prev' ,(e) => {
            $(e.currentTarget).closest('.slick-carousel').slick('slickPrev');
        });

        // Fix Slick bug. As of version 1.8.1, slick arrows do not work if appended inside '.slick-slide' (#issue 3657 - https://github.com/kenwheeler/slick/issues/3657). The following is a workaround to make the next arrow work properly.
        $('body').on('click', '.slick-next' ,(e) => {
            $(e.currentTarget).closest('.slick-carousel').slick('slickNext');
        });

        // Fix Slick bug. As of version 1.8.1, Slick does not properly handle appendDots on an element which is visible in every Slick slide (#issue #1614 - https://github.com/kenwheeler/slick/issues/1614). The following is a workaround to make Slick dots work properly.
        $('.slick-carousel').each((index, carousel) => {
            $(carousel).on('beforeChange', function (event, slick, currentSlide, nextSlide){
                $(carousel).find('.slick-dots').each((indexDots, el) => {
                    $(el).children('li').eq(currentSlide).removeClass('slick-active slick-current-dot');
                    $(el).children('li').eq(currentSlide).attr('aria-hidden', 'true');
                    $(el).children('li').eq(nextSlide).addClass('slick-active slick-current-dot');
                    $(el).children('li').eq(nextSlide).attr('aria-hidden', 'false');
                });
            });
        });

        // Initialize header-banner-carousel
        const speed = $('.header-banner-carousel').attr('data-speed') * 1000;
        $('.header-banner-carousel').slick({
            infinite: true,
            arrows: false,
            vertical: true,
            autoplay: true,
            autoplaySpeed: speed ? speed : 3000
        });
    }

    parsleyjs () {
        window.Parsley
            .addValidator('wordsLimit', {
                requirementType: 'string',
                validateString: function (value, requirement) {
                    const text = value.replaceAll(/\s+/g, ' ').trim();
                    const words = text ? text.split(' ').length : 0;
                    return words <= requirement;
                }
            });

        window.Parsley
            .addValidator('internationalPhoneNumber', {
                requirementType: 'string',
                validateString: function (value, fieldName) {
                    const input = document.querySelector(fieldName);
                    const iti = window.intlTelInputGlobals.getInstance(input);
                    return iti.isValidNumber();
                }
            });

        $('form').each((i, form) => {
            $(form).parsley({
                errorClass: 'is-invalid parsley-error',
                successClass: 'is-valid parsley-success'
            });
        });
        ageParsleyVerification();
    }

    get init () {
        return {
            bootstrapTabCollapse: this.bootstrapTabCollapse(),
            breakpointsjs: this.breakpointsjs(),
            slick: this.slick(),
            parsleyjs: this.parsleyjs(),
        };
    }
}

const plugins = new Plugins;
export default plugins;

'use strict';

var alert = require('./alerts/alertHelpers');

/**
 * ALERT NOTIFICATION API v1.0x
 * A set of methods and helper functions written in Vanilla Javascript which simplify the creation of standard alert messages according to client's specifications
 * 
 * ------------
 * Accepted properties
 * ------------ 
 * @param {String} alertName - (MANDATORY) A class name for the alert
 * @param {String} id - (OPTIONAL) An optional identifier for the alert
 * 
 * // Alert options - alertOptions: {}
 * @param {String} message - (MANDATORY) The message to attach to alert. It can be simple text or a HTML string - e.g., '<p>Your product has been added to the wishlist</p>'
 * @param {String} status - (MANDATORY) the alert status. It accepts two values: 'alert-success' for succesful alerts and 'alert-danger' for warning alerts
 * @param {String} appendTo - (OPTIONAL) The element to append the alert to. It defaults to body
 * @param {Boolean} dismissible - (OPTIONAL) If true, set a dismissible alert. Dismissible alerts have no timeout and a button which requires user interaction in order to close it
 * @param {String} prependTo - (OPTIONAL) The element to prepend the alert to. It defaults to body
 * @param {Boolean} timeout - (OPTIONAL) If false, remove the default timeout for alerts. Dismissible alerts have no timeout by default
 * 
 */

/**
 * ----------------
 * Example of usage
 * ----------------
 * var alertNotification = require('./alertNotification');
 * alertNotification.set(
    {
        alertName: 'add-to-wishlist',
        alertOptions: {
            message: data.msg,
            status: 'alert-success'    
        }
    }
);
*/

class AlertNotification {
    set ({alertName, id, alertOptions}) {
        let alertContainer = document.querySelector('.alert-messages');
        let attachToElement = alertOptions.appendTo || alertOptions.prependTo;

        if (!alertContainer && !attachToElement) {
            alert.createAlertContainer (alertName);
        }

        alert.createAlert(alertName, id, alertOptions);
    }
}

const alertNotification = new AlertNotification ();
module.exports = alertNotification;

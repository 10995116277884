'use strict';

class ChangeLocale {
    constructor () {
        this.$page = $('.page').first();
        this.$form = $('#change-locale-form').first();
        this.$language = this.$form.find('[name="change-locale-language"]');
        this.$modal = this.$form.closest('.modal-content');
    }

    init() {
        if (!this.$form.length) {
            return;
        }
        
        return {
            events: this.events()
        };
    }
    
    events () {
        // Hide the Modal into small screens
        $(window).on('resize', ()=>{
            if($(document).outerWidth() < 992){
                $('#changeLocale').modal('hide');
                return;
            }
        })

        /**
         * When updating the country select, request new
         * list of available language.
         */
        this.$form.on('change', '[name="change-locale-country"]', (e) => {
            const url = $(e.target).data('action');
            const localeCode = $(e.currentTarget).val();
            
            this.getLocales(url, localeCode);            

            e.preventDefault();
        });

        /**
         * When submitting the form, prevent default, get new URL, and
         * redirect if valid.
         */
        this.$form.on('submit', (e) => {
            e.preventDefault();

            const country = $('#change-locale-country').val();
            const languageCode = $('#change-locale-language').val();
            const currentCountry = $('#change-locale-country').data('currency-country');
            const currentLanguage = $('#change-locale-language').data('currency-language');
            const localeCurrencyCode = $('#change-locale-country').find(':selected').data('currency-code');
            const formAction = this.$form.attr('action');

            //if locale did not change, just close the modal
            if (languageCode === currentLanguage && country === currentCountry) {
                this.$modal.closest('.modal').modal('hide');
                return;
            }

            this.changeCountry(formAction, country, languageCode, this.$page.data('querystring'), localeCurrencyCode, this.$page.data('action'));
        });
    }

    /**
     * Retrieve a list of languages available for the selected country
     * @param {string} url Endpoint of the change country action
     * @param {string} localeCode Locale ID of the requested country
     */
     getLocales (url, localeCode) {
        $.ajax({
            url,
            data: {
                localeCode,
            },
            beforeSend: () => {
                this.$modal.spinner().start();
            },
            success: (response) => {
                if (!response.success) {
                    $.spinner().stop();
                    return;
                }
                /* If language list is provided, replace original select with new options */
                if (response?.countryLanguages?.length) {
                    this.$language.empty().prop('disabled', response?.countryLanguages?.length < 2);

                    response.countryLanguages.forEach(({ language, languageName, currencyCode }) => {
                        this.$language.append($('<option>').val(language).text(languageName).data('currency-code', currencyCode));
                    });
                }
            },
            complete() {
                $.spinner().stop();
            },
        });
    }

    // /**
    //  * Retrieve the localized URL for change locale redirect
    //  * @param {string} url Endpoint for the change local AJAX request
    //  * @param {string} localeCode Locale code of the redirect
    //  * @param {string} queryString Optional querystring for the current action
    //  * @param {string} localeCurrencyCode Currency code of the new locale
    //  * @param {string} action Current page action to redirect to same page
    //  */
    changeCountry (url, country = 'IT', languageCode = 'it_IT', queryString = '', localeCurrencyCode = 'EUR', action = 'Home-Show') {
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            data: {
                code: country,
                languageCode: languageCode,
                queryString,
                CurrencyCode: localeCurrencyCode,
                action,
            },
            beforeSend: () => {
                this.$modal.spinner().start();
            },
            success(response) {
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            },
            error() {
                $.spinner().stop();
            },
        });
    }
}


const changeLocale = new ChangeLocale;
export default changeLocale;

'use strict';
class Utils {
    detectDevice () {
        var classNames = [];
        if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
            classNames.push('device-ios');    
        }

        if (navigator.userAgent.match(/android/i)) {
            classNames.push('device-android');
        }

        var html = document.getElementsByTagName('html')[0];

        if (html.classList) {
            html.classList.add.apply(html.classList, classNames);
        }
    }

    loadHomePage () {
        $( '.wg1' ).ready(() => {
            $('.pre-slider').hide();
            $('.wg2, .mobile-categories').show();
        });
    }

    get init () {
        return {
            detectDevice: this.detectDevice(),
            loadHomePage: this.loadHomePage()
        };
    }
}

const utils = new Utils;
export default utils;

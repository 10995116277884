'use strict';

/**
 * By default, forms are submitted on Enter key press, preventing checkboxes from being checked/unchecked when using keyboard. This utility helps a keyboard user to check/uncheck checkboxes in a form
 * @param {String} checkboxSelector - Checkbox selector for which to prevent default form submission behavior
 */

const formCheckboxesKeyboardTrigger = checkboxSelector => {
    $('body').on('keydown', checkboxSelector, (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent user-agent default form submission behavior on Enter keydown
            $(checkboxSelector).trigger('click'); // Check/uncheck checkbox on Enter press
        }
    });
};

export default formCheckboxesKeyboardTrigger;

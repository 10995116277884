'use strict';

import formCheckboxesKeyboardTrigger from '../components/accessibility/formCheckboxesKeyboardTrigger';
import internationalPhoneNumber from '../components/internationalPhoneNumber';
import scrollIntoView from '../components/scrollIntoView';

var formValidation = require('../components/formValidation');

var productData = $('.array-data').data('productdata');
var msg = $('.array-data').data('message');
var checkoutClassDecorator = $('.checkout-pages').length;

/**
 * Send data for checkous step n° 2 (ChekoutAsGuest)
 */
function gtmSendCheckoutStepAsaGuest (){

    if( checkoutClassDecorator > 0 ){
        GtmEvents.checkoutStep(2,'',productData);
        GtmEvents.checkoutOption(2,'');
    }
}

/**
 * Send data for checkous step n° 2 (Login)
 */
function gtmSendCheckoutLogin (){

    if( checkoutClassDecorator > 0 ){
        GtmEvents.checkoutStep(2,'',productData);
        GtmEvents.checkoutOption(2,msg);
    }
}




var GtmEvents = require('../seo/gtm/gtmEvents');
class Login {
    constructor () {
        this.rememberMeInput = '#rememberMe';
    }

    login () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                        scrollIntoView('#login-registered-form');
                    } else {
                        gtmSendCheckoutLogin();
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        gtmSendCheckoutLogin();
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });

        // Prevent user-agent default form submission behavior on Enter keydown
        formCheckboxesKeyboardTrigger(this.rememberMeInput);
    }

    loginAsGuest () {
        $('form.login-as-guest').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var $registeredForm = $('#login-registered');
            if($registeredForm.is(':visible')) {
                /*
                    Case user is registered and he wants to login as guest.
                    Copy value of newsletter checkbox that is inside other visible form
                */
                if($registeredForm.find('#marketingRegistered').is(':checked')){
                    $('#marketingGuest').prop('checked', true);
                } else {
                    $('#marketingGuest').prop('checked', false);
                }
            }
            var url = form.attr('action');
            form.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login-as-guest').trigger('login:error', data);
                    } else {
                        gtmSendCheckoutStepAsaGuest();
                        $('form.login-as-guest').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    $('form.login-as-guest').trigger('login:error', data);
                    form.spinner().stop();
                }
            });
        });
    }

    showRegisterForm () {
        $('#createAccount').on('click', (e) => {
            $('form.registration').removeClass('hidden-md-down');
            $(e.currentTarget).hide();
        });
    }

    register () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        scrollIntoView('form.registration');
                    } else {
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    var payload = {
                        'error':[err.responseJSON.errorMessage]
                    };
                    formValidation(form, payload);
                    form.spinner().stop();
                }
            });
            return false;
        });
    }

    resetPassword () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');

                        $('.send-email-btn').empty()
                            .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-primary btn-block">'
                                    + data.buttonText + '</a>'
                            );
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    }

    clearResetForm () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }

    checkEmail() {
        $('#checkout-login-check-mail').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            form.trigger('login:checkEmail', e);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();

                    if (!data.success) {
                        $('#login-as-guest-form .js-email').val(data.email);
                        $('#login-as-guest-form .js-customer-email').text(data.email);

                        $('.checkout-login-form').hide();
                        $('#login-as-guest-form').show();
                    } else {
                        $('.checkout-login-form .js-email').val(data.email);
                        $('.checkout-login-form .js-customer-name').text(data.name);
                        $('.checkout-login-form .js-customer-email').text(data.email);

                        $('.checkout-login-form').hide();
                        $('#login-registered').show();
                        if (data.marketing) {
                            $('#login-registered .newsletter-container').hide();
                        }
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    var payload = {
                        'error':[err.responseJSON.errorMessage]
                    };
                    formValidation(form, payload);
                    form.spinner().stop();
                }
            });
            return false;
        });
    }

    handleEditEmail() {
        $('.js-edit-email').on('click', () => {
            $('.checkout-login-form').hide();
            $('#checkout-login-check-mail').show();
            $('.checkout-login-form .js-email').val('');
            $('.checkout-login-form .js-customer-name').text('');
            $('.checkout-login-form .js-customer-email').text('');
            $('#login-registered .newsletter-container').show();
            $('#login-registered-form .alert').remove();
        });
    }

    get init () {
        return {
            login: this.login(),
            loginAsGuest: this.loginAsGuest(),
            showRegisterForm: this.showRegisterForm(),
            register: this.register(),
            resetPassword: this.resetPassword(),
            clearResetForm: this.clearResetForm(),
            internationalPhoneNumber: internationalPhoneNumber(),
            checkEmail: this.checkEmail(),
            handleEditEmail: this.handleEditEmail()
        };
    }
}

const login = new Login;
export default login;

'use strict';

var UUIDGenerator = require('../UUIDGenerator');

/**
 * DEFAULT VALUES
 */
const _ALERTTIMEOUT = 5000; // 2 seconds
const _DISMISSIBLE = true;

/**
 * Construct the HTML for the alert, depending on whether it's a dismissible alert or not
 * @param {String} status - The status of the alert message. It accepts two values: 'alert-success' for succesful alerts and 'alert-danger' for warning alerts
 * @param {String} id - An optional identifier for the alert
 * @param {String} alertName - A class name for the alert
 * @param {Number} alertUUID - The alert identifier number
 * @param {String} message - The message to attach to alert. It can be simple text or a HTML string - e.g., '<p>Your product has been added to the wishlist</p>'
 * @param {Boolean} dismissible - If true, set a dismissible alert. Dismissible alerts have no timeout and a button which requires user interaction in order to close it
 * @returns {String} alert - The HTML repesentation of the request alert
 */

const createAlertHTML = (status, id, alertName, alertUUID, message, dismissible) => {
    let alert;
    let dwPropertyLabels = window.dwResources.properties;

    if (dismissible) {
        alert =`<div ${id ? `id="${id}"` : ''} class="alert ${status} alert-dismissible ${alertName} fade show custom-alert text-center px-5"
        role="alert" data-alert-uuid="${alertUUID}">
        <button type="button" class="close" data-dismiss="alert" aria-label="${dwPropertyLabels.close}">
        <span aria-hidden="true">&times;</span>
        </button> ${message} </div>`;
    } else {
        alert = `<div ${id ? `id="${id}"` : ''} class="alert ${status} ${alertName}-alert text-center custom-alert" role="alert"
        data-alert-uuid="${alertUUID}"> ${message} </div>`;
    }
    return alert;
};

/**
 * Set a timeout for the alert. Timeout is active by default in non-dismissible alerts. It can be disabled in any moment by setting timeout: false
 * @param {Number} alertUUID -  A universally unique identifier for the alert
 * @param {Object} alertContainer - The alert container.
 */

const _setAlertTimeout = (alertUUID, alertContainer) => {

    setTimeout(() => {
        let alert = document.querySelector(`[data-alert-uuid="${alertUUID}"]`);
        alert.parentNode.removeChild(alert);

        if (alertContainer.childElementCount == 0) {
            alertContainer.parentNode.removeChild(alertContainer);
        }
    }, _ALERTTIMEOUT);
};

/**
 * Create alert with the requested options
 * @param {String} alertName - A class name for the alert
 * @param {String} id - An optional identifier for the alert
 * @param {String} message - The message to attach to alert. It can be simple text or a HTML string - e.g., '<p>Your product has been added to the wishlist</p>'
 * @param {String} status - The alert status. It accepts two values: 'alert-success' for succesful alerts and 'alert-danger' for warning alerts
 * @param {String} appendTo - The element to append the alert to. It defaults to body.
 * @param {String} prependTo - The element to prepend the alert to. It defaults to body
 * @param {Boolean} timeout - If false, remove the default timeout for alerts. Dismissible alerts have no timeout by default
 * @param {Boolean} dismissible - If true, set a dismissible alert. Dismissible alerts have no timeout and a button which requires user interaction in order to close it
 */

const createAlert = (alertName, id, {message, status, appendTo, prependTo, timeout, dismissible}) => {
    let attachToElement = appendTo || prependTo;
    var dismissibleAlert = dismissible == false ? dismissible : _DISMISSIBLE;

    let alertContainer = document.querySelector(attachToElement) || document.querySelector('.alert-messages');
    let alertUUID = UUIDGenerator.get();
    let alertHTML = createAlertHTML(status, id, alertName, alertUUID, message, dismissibleAlert);

    if (attachToElement) {
        alertContainer.insertAdjacentHTML(appendTo ? 'beforeend' : 'afterbegin', alertHTML);
    } else {
        alertContainer.insertAdjacentHTML('afterbegin', alertHTML);

    }

    if (dismissible != true && timeout != false) {
        _setAlertTimeout(alertUUID, alertContainer);
    }
};

/**
 * Create an alert container. An alert container is automatically created when no element to attach the alert to has been specified using the appendTo and prependTo options or when no alert container already exists
 * @param {String} alertName - A class name for the alert
 */
const createAlertContainer = (alertName) => {
    const alertElementDiv = document.createElement('div');
    alertElementDiv.classList.add(`${alertName}-messages`, 'alert-messages');

    document.body.append(alertElementDiv);
};

module.exports = {
    createAlert: createAlert,
    createAlertContainer: createAlertContainer
};


'use strict';

/**
 * Construct a Universally Unique IDentifier number
 * @returns {Number} A universally unique identifier
 */

const getUUID = () => {
    let UUID = new Uint32Array(1);
    window.crypto.getRandomValues(UUID);

    return UUID[0];
};

module.exports = {
    get: getUUID
};
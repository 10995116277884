var GtmEvents = require('../gtm/gtmEvents');
/**
 * Send data to datalayer from all forms
 */
class analyticsSendFormsData{

    gtmSendFormsData (){

        $('form').on('submit',(e) => {
            GtmEvents.sendFormData($(e.currentTarget).attr('name'));
        });
    }
    get init () {
        return {
            gtmSendFormsData: this.gtmSendFormsData(),
        };
    }

}

const analyticsFormData = new  analyticsSendFormsData;
export default analyticsFormData ;
const scrollIntoView = (anchor) => {
    const headerMenuHeight = $('.header-menu:visible').outerHeight() ? $('.header-menu:visible').outerHeight() : 0;
    const headerHeadHeight = $('.header-head:visible').outerHeight() ? $('.header-head:visible').outerHeight() : 0;
    var headerHeight =  headerMenuHeight + headerHeadHeight + 100;

    $('html, body').animate(
        {
            scrollTop: $(anchor).offset().top - headerHeight
        }, 700
    );
};

export default scrollIntoView;

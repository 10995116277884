'use strict';

function sendPageData (pageLang,pageType,storeView,visitorId,loginState,visitorType,existingCustomer,lifetimeValue,newsletterSubscription){
    window.dataLayer.push({
        'pageLang': pageLang,
        'pageType': pageType,
        'storeView': storeView,
        'visitorId': visitorId,
        'visitorLoginState': loginState,
        'visitorType': visitorType,
        'visitorExistingCustomer': existingCustomer,
        'visitorLifetimeValue': lifetimeValue,
        'visitorNewsletterSubscription':newsletterSubscription
    });
}
/** sendSearchData: sends the search event to the dataLayer.
 *@param {int} searchResultsCount: number of search results
 */
function sendSearchData (searchResultsCount) {
    window.dataLayer.push({
        'event': 'search',
        'searchResults': searchResultsCount
        });
}

function sendPromoData (promotions){
    window.dataLayer.push({
        'ecommerce': {
            'promoView': {
                'promotions': promotions
            }
        }
    });
}

function sendReserchData (queryReserch,reserchResult){
    window.dataLayer.push({
        'event': 'inside-reserch',
        'queryReserch': queryReserch,
        'reserchResult': reserchResult

    });
}


function sendNewsletterSuccess (){
    window.dataLayer.push({
        'event': 'submit-form-newsletter-ok'
    });
}

function sendFormData (formName){
    window.dataLayer.push({
        'event': 'submit-form-'+ formName +'-ok'
    });
}

function sendFormRegistrationData (){
    window.dataLayer.push({
        'event': 'submit-form-registration-ok'
    });
}

function sendFormLoginData (){
    window.dataLayer.push({
        'event': 'submit-form-login-ok'
    });
}

function sendProductDetailsData (currencyCode,products){
    window.dataLayer.push({
        'event': 'productDetail',
        'ecommerce': {
            'detail': {
                'currencyCode': currencyCode,
                'actionField': {'list':''},
                'products': products
            }
        }
    });

}

function sendProductClickData (currency,products,list){
    window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'currencyCode': currency,
                'actionField': {'list': list},
                'products': products
            }
        }
    });
}

function addToCart (currencyCode,products){
    window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce':{
            'currecyCode': currencyCode,
            'add': {
                'products': products
            }
        }
    });

}

function removeFromCart (currencyCode,products){
    window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce':{
            'currencyCode':currencyCode,
            'remove': {
                'products': products
            }
        }
    });

}

function sendPurchaseData (currencyCode, orderID, shopName, total, tax, shippingCosts, couponCode, discountedAmount, products){
    window.dataLayer.push({
        'event': 'purchase',
        'ecommerce': {
            'currencyCode': currencyCode,
            'purchase':{
                'actionField':{
                    'id': orderID,
                    'affilation': shopName,
                    'revenue': total,
                    'tax': tax,
                    'shipping': shippingCosts,
                    'coupon': couponCode,
                    'discountedAmount': -discountedAmount,
                },
                'products': products
            }
        }
    });

}
function checkoutStep (step,option,products){
    window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'checkout':{
                'actionField': {'step': step,'option': option},
                'products': products

            }

        }

    });

}

function checkoutOption (step,checkoutOption){
    window.dataLayer.push({
        'event': 'checkoutOption',
        'ecommerce': {
            'checkout_option': {
                'actionField': {'step': step, 'option': checkoutOption}
            }
        }
    });
}

function addToWishlist (productName){
    window.dataLayer.push({
        'event': 'add-to-wishlist',
        'productName': productName
    });
}

function filterData (filterType,filterValue){
    window.dataLayer.push({
        'event': 'applicazione-filtro-listing',
        'filterType': filterType,
        'filterValue': filterValue
    });
}
function listingOrder (order){
    window.dataLayer.push({
        'event': 'application-order-listing',
        'orderValue': order
    });
}

function sendCartDisplay (currencyCode,products){
    window.dataLayer.push({
        'event': 'cartDisplay',
        'ecommerce': {
            'currencyCode': currencyCode,
            'cart': {
                'products': products
            }
        }
    });
}

module.exports = {
    sendPageData: sendPageData,
    sendPromoData: sendPromoData,
    sendReserchData: sendReserchData,
    sendNewsletterSuccess: sendNewsletterSuccess,
    sendFormData: sendFormData,
    sendFormRegistrationData : sendFormRegistrationData,
    sendFormLoginData: sendFormLoginData,
    sendProductDetailsData: sendProductDetailsData,
    sendProductClickData: sendProductClickData,
    sendCartDisplay: sendCartDisplay,
    addToCart: addToCart,
    removeFromCart: removeFromCart,
    sendPurchaseData: sendPurchaseData,
    checkoutStep: checkoutStep,
    checkoutOption: checkoutOption,
    addToWishlist:addToWishlist,
    filterData: filterData,
    listingOrder: listingOrder,
    sendSearchData: sendSearchData
};

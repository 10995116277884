'use strict';

/**
 * SizeChart module - // sizeChart module is available globally due to quickView modals
 */

import focusOut from '../components/accessibility/focusOut';

class SizeChart  {

    sizeChart () {
        const self = this;

        $('body').on('click', '.size-chart .js-size-guide-trigger' ,function (e) {
            e.preventDefault();

            var $sizeChart = $('#sizeChartModal .modal-body');
            var url = $(this).attr('href');

            if ($sizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'html',
                    success: function (data) {
                        $sizeChart.append(data);
                        self.handleSizeGuideMobileMenu();
                        self.handleTableOverflowOverlay();
                        self.handleSwitcher();
                    }
                });
            }
        });

        // $('body').on('click touchstart', function (e) {
        //     if ($('.size-chart').has(e.target).length <= 0) {
        //         var $sizeChart = $('.size-chart-collapsible');
        //         $sizeChart.removeClass('active');
        //     }
        // });

        // // Accessibility. Close size chart modal on focus out
        focusOut('#sizeChartModal', (currentTarget) => {
            $(currentTarget).modal('hide');
        });

    }

    handleSizeGuideMobileMenu () {
        const activeTabText = $('#size-guide-tabs [data-toggle="tab"].active').text();

        $('#size-guide-mobile-menu .dropdown-toggle span').text(activeTabText);

        $('#size-guide-mobile-menu .dropdown-menu button').on('click', (e) => {
            const target = $(e.currentTarget).data('target-tab');
            $(target, '#size-guide-tabs').tab('show');
        });

        $('#size-guide-tabs [data-toggle="tab"]').on('show.bs.tab', (e) => {
            const targetText = $(e.target).text().trim();
            $('#size-guide-mobile-menu .dropdown-toggle span').text(targetText);
        });
    }

    handleSwitcher () {
        $('#sizeChartModal table tbody tr[data-alt]').hide();

        $(document).on('change', '.switcher input', (e) => {
            const targetTable = $(e.currentTarget).data('target');
            const changeMode = $(e.currentTarget).data('change-mode');

            if (changeMode === 'row') {
                $('tbody tr[data-alt]', targetTable).toggle();
                $('tbody tr', targetTable).not('[data-alt]').toggle();
            } else {
                const switchData = $('[data-alt]', targetTable);

                switchData.each((i, el) => {
                    const altValue = $(el).data('alt');
                    const currentValue = $(el).text();
                    $(el).text(altValue);
                    $(el).data('alt', currentValue);
                });
            }
        });
    }


    handleTableOverflowOverlay () {
        $('.size-table').on('scroll', (e) => {
            const table = $(e.currentTarget);
            const tbody = $('tbody', table);
            const tableWidth = table.width();
            const tbodyWidth = tbody.width();
            const position = Math.abs(Math.round(tbody.position().left));
            const width = Math.round(tbodyWidth - tableWidth);

            table.closest('.table-container').toggleClass('end', position >= width);
        });
    }

    get init () {
        return {
            sizeChart: this.sizeChart(),
        };
    }
}

const sizeChart = new SizeChart;
export default sizeChart;

/**
 * Detect a wrapper (including all its childs) focus loss. This function is especially useful for keyboard accessibility, when you want to execute some code when an entire wrapper loses focus
 * @param {Object} wrapper - The monitored wrapper
 * @param {Object} callback - The function to execute when an entire wrapper loses focus
 */

const focusOut = (wrapper, callback) => {
    $('body').on('keydown focusout', wrapper, function (e) {
        if(e.type === 'keydown' && e.key === 'Tab') {
            var $elem = $(this);
            // setTimeout required to wait for next element to receive focus
            setTimeout(function () {
                var hasFocus = !!($elem.find(':focus').length > 0);
                if (!hasFocus) {
                    callback.call(this, $elem);
                }
            }, 12);
        }
    });
};

export default focusOut;

'use strict';

var alertNotification = require('../components/alertNotification');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl (url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
  * Create an alert to display the error message if Geolocation.getCurrentPosition is not succesful
  * @param {Object} err - The GeolocationPositionError interface
  */

function createErrorNotification (err) {
    $.spinner().stop();

    var errorMessage = '';
    if (err.code === 1) {
        errorMessage = $('.detect-location').attr('data-error-permissions');
    } else {
        errorMessage = $('.detect-location').attr('data-error-generic');
    }
    
    alertNotification.set({
        alertName: 'store-locator',
        alertOptions: {
            message: errorMessage,
            status: 'alert-danger'
        }
    });
}

/**
 * Uses google maps api to render a map
 */

function maps () {
    var mapStyles = require('./mapStyles.json');

    var autocomplete; // The autocomplete box in the StoreLocator page and the ship2store panel on checkout
    var geocodingInput = document.getElementById('store-geocode-place'); // geocodingInput must be an Element object, not a jQuery object
    var map;
    var infowindow = new google.maps.InfoWindow(); // Box which pops up by clicking on a store marker in the map

    var latlng = new google.maps.LatLng(37.09024, -95.712891); // Center map in an arbitrary position if no store is found

    var mapOptions = {
        scrollwheel: false,
        zoom: 7,
        center: latlng,
        styles: mapStyles
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);

    if (geocodingInput) {
        autocomplete = new google.maps.places.Autocomplete(geocodingInput);
        autocomplete.bindTo('bounds', map);
    }

    var mapdiv = $('.map-canvas').attr('data-locations');
    mapdiv = JSON.parse(mapdiv);

    var bounds = new google.maps.LatLngBounds();

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        var marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: item.name
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);
    });

    // Set the center of the map at the center of extended bounds if only one store is found
    if (mapdiv && mapdiv.length === 1) {
        map.setCenter(bounds.getCenter());
    // Fit all the store marks in the center of a minimum bounds when stores have been found.
    } else if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */

function updateStoresResults (data) {
    var $breadcrumbs = $('.store-locator-breadcrumbs');
    var $resultsDiv = $('.results');
    var $mapDiv = $('.map-canvas');
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        $('.store-locator-no-results').show();
    } else {
        $('.store-locator-no-results').hide();
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    $breadcrumbs.empty().html(data.breadcrumbs); // Update breadcrumbs in the Store Locator page

    if ($mapDiv.data('has-google-api')) {
        maps();
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }
}

/**
 * Geocode stores using the provided address
 * @param {HTMLElement} element - the target html element
* @param {String} address - The address to geocode
 */

function geocodeSearch (element, address) {
    var geocoder;
    geocoder =  new google.maps.Geocoder();

    var noDecorator = $('#boutique-tab').length > 0; // The noDecorator URL parameter. This parameter is used to properly render the boutique panel during the checkout process. See storeLocatorResults.isml for details about the use of noDecorator parameter

    geocoder.geocode({'address': address}, (results, status) => {
        if (status === 'OK') {
            var $form = element.closest('.store-locator-container').find('form.store-locator');
            var radius = $('.results').data('radius');
            var url = element.attr('href') ? element.attr('href') : $form.attr('action'); // Check if a breadcrumb link has been clicked

            var lat = results[0].geometry.location.lat();
            var long = results[0].geometry.location.lng();

            var urlParams = {
                lat: lat,
                long: long,
                ...radius && {radius: radius}, // Short-circuit evaluation. Add radius to urlParams only if defined
                ...noDecorator && {noDecorator: noDecorator} // Short-circuit evaluation. Add noDecorator to urlParams only if defined
            };

            url = appendToUrl(url, urlParams);

            if(element.closest('.store-detail-page').length > 0) {
                window.location.href = url; // Redirect the user to the Store Locator page when a breadcrumb item is clicked in the Store Detail page
            } else {
                $.spinner().start();

                $.ajax({
                    url: url,
                    type: 'get',
                    data: {
                        geocodeAddressRequest: JSON.stringify(results[0].address_components)
                    },
                    dataType: 'json',
                    success: function (data) {
                        updateStoresResults(data);
                        $.spinner().stop();
                    }
                });
            }
        } else {
            $.spinner().stop();
            return;
        }
    });
}

/**
 * Reverse geocode service. It gives back a locality starting from latitude and longitude
 */

function reverseGeocodeSearch () {
    var geocoder;
    geocoder =  new google.maps.Geocoder();

    var noDecorator = $('#boutique-tab').length > 0; // The noDecorator URL parameter. This paramter is used to properly render the boutique panel during the checkout process.

    navigator.geolocation.getCurrentPosition(function (position) {
        var $detectLocationButton = $('.detect-location');
        var url = $detectLocationButton.data('action');
        var radius = $('.results').data('radius');

        var urlParams = {
            radius: radius,
            lat: position.coords.latitude,
            long: position.coords.longitude,
            ...noDecorator && {noDecorator: noDecorator} // Short-circuit evaluation. Add noDecorator to urlParams only if defined
        };

        var latlng = {lat:  position.coords.latitude, lng:  position.coords.longitude};

        geocoder.geocode({'location': latlng}, function (results, status) {
            if (status === 'OK') {
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    data: {geocodeAddressRequest: JSON.stringify(results[0].address_components)},
                    dataType: 'json',
                    success: function (data) {
                        $.spinner().stop();
                        updateStoresResults(data);
                        $('.select-store').prop('disabled', true);
                    }
                });
            } else {
                $.spinner().stop();
                return;
            }
        });
    }, createErrorNotification);
}

class StoreLocator {
    initMap () {
        if ($('.map-canvas').data('has-google-api')) {
            maps();
        } else {
            $('.store-locator-no-apiKey').show();
        }

        if (!$('.results').data('has-results')) {
            $('.store-locator-no-results').show();
        }
    }

    detectLocation () {
        // clicking on detect location.
        $('.detect-location').on('click', function () {
            $.spinner().start();
            if (!navigator.geolocation) {
                $.spinner().stop();
                return;
            } else {
                reverseGeocodeSearch();
            }
        });
    }

    geocodeSearch () {
        $('.store-locator-container form.store-locator').submit(function (e) {
            e.preventDefault();

            var address = document.getElementById('store-geocode-place').value;
            geocodeSearch($(this), address);
        });
    }

    geocdeSearchFromBreadcrumbs () {
        $('body').on('click', '.breadcrumb-item a.isGeocodable', (e) => {
            e.preventDefault();

            var address = e.currentTarget.textContent.trim();
            geocodeSearch($(e.currentTarget), address);
        });
    }

    selectStore () {
        $('.store-locator-container').on('click', '.select-store', (function (e) {
            e.preventDefault();
            var selectedStore = $(':checked', '.results-card .results');
            var data = {
                storeID: selectedStore.val(),
                searchRadius: $('#radius').val(),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
                event: e
            };

            $('body').trigger('store:selected', data);
        }));
    }

    updateSelectStoreButton () {
        $('body').on('change', '.select-store-input', (function () {
            $('.select-store').prop('disabled', false);
        }));
    }

    get init () {
        return {
            initMap: this.initMap(),
            detectLocation: this.detectLocation(),
            geocodeSearch: this.geocodeSearch(),
            geocdeSearchFromBreadcrumbs: this.geocdeSearchFromBreadcrumbs(),
            selectStore: this.selectStore(),
            updateSelectStoreButton: this.updateSelectStoreButton()
        };
    }
}

const storeLocator = new StoreLocator;
export default storeLocator;
